<template>
	<v-card class="d-flex flex-column my-card">
		<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-6">
			<v-col cols="12">
				<p class="heading-sf20">My information</p>
			</v-col>
		</v-row>
		<v-form v-model="valid" ref="form" lazy-validation>
			<v-row no-gutters dense>
				<v-col cols="12" sm="6" class="pr-sm-2">
					<v-row no-gutters dense>
						<v-col cols="12" class="pb-0">
							<label>First Name</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('first_name', $event)"
								single-line
								outlined
								dense
								height="48"
								color="#CACEDA"
								type="text"
								:value="firstname"
								:rules="firstnameRules"
								placeholder="First name"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="6" class="pl-sm-2">
					<v-row no-gutters dense>
						<v-col cols="12" class="pb-0">
							<label>Last Name</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('last_name', $event)"
								single-line
								outlined
								dense
								height="48"
								color="#CACEDA"
								type="text"
								:value="lastname"
								:rules="lastnameRules"
								placeholder="Last name"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="6" class="pr-sm-2">
					<v-row no-gutters dense class="p-0">
						<v-col cols="12" class="pb-0">
							<label>Phone</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('phone', $event)"
								single-line
								outlined
								dense
								height="48"
								color="#CACEDA"
								type="text"
								:value="phone"
								:rules="phoneRules"
								placeholder="Phone"
								v-mask="mask"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="6" class="pl-sm-2">
					<v-row no-gutters dense class="p-0">
						<v-col cols="12" class="pb-0">
							<label>Email</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('email', $event)"
								single-line
								outlined
								dense
								height="48"
								color="#CACEDA"
								type="text"
								readonly
								:value="email"
								:rules="emailRules"
								placeholder="Email address"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-form>
		<v-row no-gutters dense align="stretch" class="d-flex">
			<v-col v-if="error_message != ''" class="d-flex align-center mt-auto p-0 custom-error" cols="12">{{ this.error_message }}</v-col>
			<v-col cols="12" class="d-flex justify-end mt-auto">
				<v-btn class="my-auto-btn my_elevation" id="my_elevation" @click="myInformation()" :disabled="!valid" depressed>Save changes</v-btn>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
// import { mask } from "vue-the-mask";
import { userProfileUpdate } from '../../services/authservices'
export default {
	data() {
		return {
			active_tab: false,
			mask: '(###)-###-####',
			show: true,
			valid: true,
			error_message: '',
			firstnameRules: [(v) => !!v || 'First name is required', (v) => /^[A-Za-z]+$/.test(v) || 'Input alphabet character only'],
			lastnameRules: [(v) => !!v || 'Last name is required', (v) => /^[A-Za-z]+$/.test(v) || 'Input alphabet character only'],
			phoneRules: [(v) => !!v || 'Phone number is required', (v) => (v && v.length >= 14 && v.length <= 14) || 'Phone number must be 10 digit'],
			emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],

			my_information: {
				first_name: '',
				last_name: '',
				phone: '',
				email: '',
			},
		}
	},

	created() {
		this.$store.watch(
			(state) => {
				return state.auth.error
			},
			() => {
				if (this.active_tab === true) {
					this.error_message = this.$store.state.auth.error
				}
			},
			{ deep: true }
		)
	},

	async mounted() {
		await this.userData()
	},

	watch: {
		phone(value) {
			this.$emit('input', value)
		},
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true)
		},
		firstname() {
			return this.$store.state.auth.user_data.first_name
		},
		lastname() {
			return this.$store.state.auth.user_data.last_name
		},
		phone() {
			return this.$store.state.auth.user_data.phone
		},
		email() {
			return this.$store.state.auth.user_data.email
		},
	},

	methods: {
		updateValue(fieldname, event) {
			let ob = {
				fieldname: fieldname,
				value: event.target.value,
			}
			this.$store.commit('updateUserValue', ob)
		},

		userData() {
			this.error_message = ''
			let user = this.$store.state.auth.user_data

			this.my_information.first_name = user.first_name
			this.my_information.last_name = user.last_name
			this.my_information.phone = user.phone
			this.my_information.email = user.email
		},

		async myInformation() {
			if (this.$refs.form.validate()) {
				this.$store.commit('errorMessage', '')
				this.error_message = ''
				this.active_tab = true

				await this.userData()

				let user_info = {
					fullname: this.my_information.first_name + ' ' + this.my_information.last_name,
					phone: this.my_information.phone,
				}
				let data = await userProfileUpdate(user_info)

				if (!data.data) {
					this.error_message = this.$store.state.auth.error
				} else {
					this.$store.commit('authUser', data)
					this.userData()
					this.active_tab = false
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
